import 'react-toastify/dist/ReactToastify.css';
import '@fontsource/kanit/200.css';
import '@fontsource/poppins/latin.css';
import '@fontsource/muli/latin.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
// eslint-disable-next-line import/no-unresolved
import './src/styles/global.css';
import './src/styles/common.css';

const customViewportCorrectionVariable = 'vh';

// eslint-disable-next-line import/prefer-default-export
export const onClientEntry = () => {
  const setViewportProperty = (doc) => {
    let prevClientHeight;
    const customVar = `--${customViewportCorrectionVariable || 'vh'}`;
    const handleResize = () => {
      const { clientHeight } = doc;
      if (clientHeight === prevClientHeight) return;
      requestAnimationFrame(() => {
        const newHeight = Math.round(clientHeight + Number.EPSILON) / 100;
        doc.style.setProperty(customVar, `${newHeight}px`);
        prevClientHeight = clientHeight;
      });
    };
    handleResize();
    return handleResize;
  };
  if (typeof window !== 'undefined') window.addEventListener('resize', setViewportProperty(document.documentElement));
};
